<template>
  <div>
    <div class="flix-well">
      <h3 class="flix-html-h3 flix-text-center">{{ $tc('message.pageBuilder', 1) }}</h3>
      <div class="flix-form-group">
        <input class="flix-form-control flix-text-center" ref="url" @click="setFocus" :value="url" readonly="true"/>
      </div>
      <transition name="flixFadeIn">
        <div class="flix-form-group" v-if="copied" :key="copied">
          <div class="flix-alert flix-alert-info">{{ $t('message.copy', {name: $tc('message.pageBuilder', 1)}) }}</div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      copied: false,
      url: this.$flix_url + this.$router.resolve({
        name: 'privateEvent',
        params: this.$route.params
      }).href
    }
  },
  methods: {
    setFocus (event) {
      event.target.select()
      try {
        document.execCommand('copy')
        this.copied = true
        setTimeout(function () {
          this.copied = false
        }.bind(this), 2000)
      } catch (err) {
        alert('Oops, unable to copy')
      }
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  input
    cursor: pointer
</style>
